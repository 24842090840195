<template>
  <div
    class="page"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="search_row">
      <div class="btn_list">
        <el-button type="primary" @click="addClick">新增</el-button>
        <el-button class="btn_two" @click="showDialog" plain>分配企业</el-button>
        <div class="row_item">
          <div class="label">商机类别：</div>
          <el-radio-group v-model="queryInfo.businessOpportunityCategory" @change="changeSearchangeSearch">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">潜在需求</el-radio>
            <el-radio :label="2">真实需求</el-radio>
          </el-radio-group>
        </div>
      </div>
      <!-- 右边搜索 -->
      <div class="search_right">
        <el-input
          placeholder="请输入标签组名称、标签组描述"
          v-model="queryInfo.keys"
          clearable
          @clear="changeSearchangeSearch"
          class="input-with-select"
        >
          <el-button slot="append" @click="changeSearchangeSearch" type="primary">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="table_box">
      <!-- 表单区域 -->
      <el-table
        class="table_data"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
        :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        :row-key="getRowKey"
        @sort-change="changeSort"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
        <el-table-column prop="businessId" label="编号" align="center" width="80">
        </el-table-column>
        <el-table-column label="封面图" align="center" width="100">
          <template v-slot="{ row }">
            <img :src="row.businessImage" class="img" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="商机名称" align="center"> </el-table-column>

        <el-table-column label="商机类别" align="center" width="100">
          <template v-slot="{ row }">
            <div
              class="border_box"
              :class="row.businessOpportunityCategory === 2 ? 'real_box' : ''"
            >
              {{ row.businessOpportunityCategory === 2 ? '真实需求' : '潜在需求' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="标签" width="200">
          <template v-slot="{ row }">
            <el-tooltip effect="dark" :visible-arrow="false" placement="bottom">
              <div slot="content">
                <div class="relate_tool_tip">{{ row.businessLabel }}</div>
              </div>
              <div class="relate_tools">
                <span class="color1">{{ row.businessLabel }}</span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="companyCount" label="推送企业 " align="center">
          <template v-slot="{ row }">
            <div style="cursor: pointer">{{ row.companyCount ? row.companyCount : 0 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="上传人 " align="center"> </el-table-column>
        <el-table-column prop="createTime" label="上传时间" sortable align="center">
          <template v-slot="{ row }">
            <div class="time_box">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="140">
          <template v-slot="{ row }">
            <div class="btn_list">
              <el-button type="primary" @click="toConfigEnterprise(row)"> 配置企业 </el-button>
              <span :class="row.notIssue ? 'color2' : 'color1'" @click="setIssue(row)">
                {{ row.notIssue ? '下架' : '发布' }}
              </span>
              <span class="color1" @click="toDetail(row.businessId)">编辑</span>
              <span :class="row.notFreeze ? 'color1' : 'color2'" @click="setFreeze(row)">
                {{ row.notFreeze ? '解冻' : '冻结' }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="分配企业"
      :visible.sync="dialogVisible"
      width="60%"
      class="account_dialog"
      v-loading="dialogLoading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
    >
      <div class="dialog_content">
        <el-form label-position="left" :model="queryInfoDialog" ref="form">
          <el-form-item label-width="100px" label="关联运营商：" prop="">
            <div class="btn_div_list">
              <div
                class="ft-14 item"
                :class="queryInfoDialog.operatorId === 0 ? 'li_is_active' : ''"
                @click="operatorClick(0)"
              >
                全部
              </div>
              <div
                v-for="item in operatorList"
                :key="item.operatorId"
                class="ft-14 item"
                :class="queryInfoDialog.operatorId === item.operatorId ? 'li_is_active' : ''"
                @click="operatorClick(item.operatorId)"
              >
                {{ item.operatorName }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label-width="82px" label="所在地区:" prop="">
            <el-select
              v-model="queryInfoDialog.province"
              placeholder="请选择省份"
              @change="citychange"
            >
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="item in operatingList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div style="display: inline-block; width: 10px"></div>
            <el-select
              v-model="queryInfoDialog.city"
              placeholder="请选择市"
              @change="districtchange"
            >
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="item in operatingLists"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div style="display: inline-block; width: 10px"></div>
            <el-select v-model="queryInfoDialog.district" placeholder="请选择区" @change="search">
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="item in districtList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="82px" label="企业全称：" prop="">
            <div class="row">
              <el-input
                placeholder="请输入企业全称"
                v-model="queryInfoDialog.companyFullName"
                class="input_box"
              >
              </el-input>
              <el-button type="primary" @click="dialogSearch">搜索</el-button>
              <el-button type="info" @click="resetQueryInfo">重置</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div class="dialog_table_box">
          <el-table
            ref="dialogTable"
            :data="dialogTbaleData"
            stripe
            width="100%"
            @selection-change="handleSelectionChangeDialog"
            row-key="id"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" align="center" :reserve-selection="true" />
            <el-table-column label="企业头像" width="80" prop="companyLogo" align="center">
              <template v-slot="{ row }">
                <img v-if="row.companyLogo" class="img_box" :src="row.companyLogo" alt="" />
                <img
                  v-else
                  class="img_box"
                  :src="require('@/assets/image/Group 3237.png')"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column label="企业全称" prop="companyFullName" align="center">
            </el-table-column>
            <el-table-column label="所在区域" prop="userName" align="center">
              <template v-slot="{ row }">
                <span v-if="row.province">
                  {{ row.province }}
                </span>
                <span v-if="row.city">
                  {{ row.city }}
                </span>
                <span v-if="row.district">
                  {{ row.district }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              label="关联运营商"
              prop="operatorName"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfoDialog.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfoDialog.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dialogTotal"
            @size-change="handleSizeChangeDialog"
            @current-change="handleCurrentChangeDialog"
          />
        </div>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="dialogConfimBtn">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  nicheList,
  nicheRelease,
  nicheFreeze,
  listCompanyIdAutomation,
  listOperatorName,
  listAllocationPage,
  saveAllocation
} from '@/api/operatorCenter'
import { getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  orders: 1, // 默认传1 2->时间升序;3->时间降序
  pageNum: 1,
  pageSize: 10,
  businessOpportunityCategory: 0, // 商机类别 1->潜在需求;2->真实需求
  keys: '' // 商机描述/商机名称
})
const defaultQueryInfoDialog = Object.freeze({
  ids: [],
  pageNum: 1,
  pageSize: 10,
  city: '',
  district: '',
  province: '',
  operatorId: null,
  companyFullName: ''
})
export default {
  name: 'NicheExcavate',
  data() {
    return {
      dialogLoading: false,
      clickCount: 0, // 节流阀
      operatorList: [],
      queryInfo: { ...defaultQueryInfo },
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [], //区
      dialogVisible: false,
      queryInfoDialog: { ...defaultQueryInfoDialog },
      dialogTbaleData: [],
      dialogTotal: 0,
      dialogIds: [],
      ids: [],
      idsList: [],
      tableData: [],
      total: 0,
      loading: false
    }
  },
  created() {
    this.incity()
    // this.search()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
    this.searchOperatorList()
  },
  methods: {
    // 商机查询一览
    async search() {
      this.loading = true
      const { data: res } = await nicheList(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        // this.$refs.multipleTable.clearSelection()
      } else {
        this.$message.error('查询失败！')
      }
      this.loading = false
    },
    // 查询所有运营商列表
    async searchOperatorList() {
      const { data: res } = await listOperatorName()
      if (res.resultCode === 200) {
        if (res.data && res.data.length) {
          this.operatorList = res.data
          this.queryInfoDialog.operatorId = 0
        }
      }
    },
    // 配置企业按钮
    async toConfigEnterprise(row) {
      this.loading = true
      const { businessId, businessLabel } = row
      const query = {
        businessId,
        businessLabel
      }
      const { data: res } = await listCompanyIdAutomation(query)
      if (res.resultCode == 200) {
        this.loading = false
        this.$router.push({
          path: '/operatorcenter/nicheexcavate/matching',
          query: {
            businessId
          }
        })
      }
    },
    // 发布、下架
    async setIssue(row) {
      const txt = row.notIssue ? '下架' : '发布'
      const businessId = row.businessId
      const { data: res } = await nicheRelease({ businessId })
      if (res.resultCode === 200) {
        this.$message.error(txt + '成功！')
        this.search()
      } else {
        this.$message.error(txt + '失败！')
      }
    },
    // 冻结、解冻
    async setFreeze(row) {
      const txt = row.notFreeze ? '下架' : '发布'
      const businessId = row.businessId
      const { data: res } = await nicheFreeze({ businessId })
      if (res.resultCode === 200) {
        this.$message.error(txt + '成功！')
        this.search()
      } else {
        this.$message.error(txt + '失败！')
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化 配置智参中心
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      localStorage.setItem('paginationCurrentPage', newCurrent)
      this.search()
    },
    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.businessId)
      // this.single = selection.length !== 1
      // this.multiple = !selection.length
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order

      if (val.prop == 'loginCount') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'login_count ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'login_count DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'update_time DESC'
          this.search()
        }
      } else if (val.prop == 'updateTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'update_time ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'update_time DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'update_time DESC'
          this.search()
        }
      }
    },
    // 去详情 新增
    addClick() {
      this.$router.push({
        path: '/operatorcenter/nicheexcavate/details',
        query: {
          activeTab: 0
        }
      })
    },
    // 去详情 编辑
    toDetail(businessId) {
      this.$router.push({
        path: '/operatorcenter/nicheexcavate/details',
        query: {
          businessId,
          activeTab: 0,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    // 重置节流阀
    resetClickCount() {
      this.clickCount++
      setTimeout(() => {
        this.clickCount = 0
      }, 1500)
    },
    // 弹窗
    showDialog() {
      if (this.clickCount === 0) {
        this.resetClickCount()
        if (this.ids.length < 1) {
          this.$message.warning('请先选择商机~')
        } else {
          this.dialogSearch()
          this.dialogVisible = true
        }
      }
    },
    // 多选
    handleSelectionChangeDialog(selection) {
      this.dialogIds = selection.map((item) => item.companyId)
    },
    // 切换关联运营商
    operatorClick(val) {
      this.queryInfoDialog.operatorId = val
      this.dialogSearch()
    },
    // 重置
    resetQueryInfo() {
      this.queryInfoDialog = { ...defaultQueryInfoDialog }
      this.dialogSearch()
    },
    //城市
    async incity() {
      const { data: res } = await getCityAll()
      if (res.resultCode == 200) {
        this.operatingList = res.data
      }
    },
    //选择城市
    citychange(item) {
      console.log(item)
      this.operatingList.forEach((o) => {
        if (this.queryInfoDialog.province == o.name) {
          this.queryInfoDialog.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfoDialog.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.dialogSearch()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfoDialog.city == o.name) {
          this.queryInfoDialog.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.dialogSearch()
    },
    //分页查询 弹窗
    async dialogSearch() {
      this.queryInfoDialog.ids = this.ids
      const { data: res } = await listAllocationPage(this.queryInfoDialog)
      if (res.resultCode == 200) {
        this.dialogTbaleData = res.data.list
        this.dialogTotal = res.data.total
        // this.$refs.dialogTable.clearSelection()
      }
    },
    // 分页 弹窗
    handleSizeChangeDialog(val) {
      this.queryInfoDialog.pageSize = val
      this.dialogSearch()
    },
    // 分页 弹窗
    handleCurrentChangeDialog(val) {
      this.queryInfoDialog.pageNum = val
      this.dialogSearch()
    },
    // 确定按钮 弹窗
    async dialogConfimBtn() {
      this.dialogLoading = true
      if (this.clickCount === 0) {
        this.resetClickCount()
        const companyIdListParams = this.dialogIds.map((el) => {
          let obj = {
            companyId: el
          }
          return obj
        })
        const paramList = this.ids.map((el) => {
          let obj = {
            businessId: el
          }
          return obj
        })
        const query = {
          companyIdListParams,
          paramList
        }
        const { data: res } = await saveAllocation(query)
        if (res.resultCode === 200) {
          this.$message.success('分配成功！')
          this.ids = []
          this.dialogIds = []
          this.dialogVisible = false
          this.search()
          this.dialogLoading = false
        } else {
          this.dialogLoading = false
          this.$message.success('分配失败！')
        }
      }
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  font-family: 'PingFang SC';
  background-color: #fff;
  border-radius: 8px;
  padding: 14px;
  box-sizing: border-box;

  .input_box {
    width: 300px;
  }
  .search_right {
    width: 460px;
    margin-left: auto;
    // margin-right: 20px;
  }
  .search_right ::v-deep .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }

  .row {
    display: flex;
  }
  .row_item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .label {
      font-size: 14px;
      line-height: 20px;
      color: #333;
    }
  }
}
.search_row {
  display: flex;
  margin-bottom: 10px;
  .btn_list {
    display: flex;
    align-items: center;
    ::v-deep .btn_two {
      color: #448aff;
      border: 1px solid #448aff;
    }
    ::v-deep .btn_two:hover {
      opacity: 0.7;
      background-color: rgba(68, 138, 255, 0.2);
    }
  }
}
.table_box {
  height: calc(100% - 100px);
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .relate_tool_tip {
    width: 200px;
  }
  .relate_tools {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
  }
  .btn_list {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .img {
    width: 72px;
    height: 48px;
    display: block;
    border-radius: 4px;
  }
  .time_box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .border_box {
    width: 80px;
    height: 32px;
    text-align: center;

    border-radius: 2px;
    font-size: 12px;
    line-height: 32px;
    border: 0.5px solid #4e93fb;
    color: #4e93fb;
    background-color: #4e93fb1a;
  }
  .real_box {
    border: 0.5px solid #fb824e;
    color: #fb824e;
    background-color: #fb824e1a;
  }
}
.ft-14 {
  font-size: 14px;
  line-height: 20px;
}
.el-dialog {
  .dialog_content {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }
  .input_box {
    margin-right: 10px;
  }
  .btn_div_list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    line-height: 40px;
    .item {
      min-width: 60px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      padding: 0 6px;
      cursor: pointer;
      margin-right: 10px;
    }
    .li_is_active {
      border-radius: 4px;
      border: 1px solid #4e93fb;
      color: #4e93fb;
    }
  }
  .dialog_table_box {
    width: 100%;
    height: 400px;
    overflow-y: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
    .img_box {
      width: 42px;
      height: 42px;
      display: block;
    }
  }
}
.new_page {
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  // border-top: #fafafa 1px solid;
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #fd5469;
}
.color3 {
  color: #333;
}
</style>
